import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    count: 0,
    userId: '1',
    lists: [],
    username: '用户名',
    token: '',
    num_japanese: 0,
    num_french: 0,
  },
  mutations: {
    increate1 () {
      this.state.count++
    },
    addItem (state, value) {
      state.lists.push(value)
    },
    setUserId (state, id){
      this.state.userId = id
    },
    setToken(state, token) {
      this.state.token = token
    },
    setUserName (state, username) {
      this.state.username = username
    },
    setNumJapanese (state, num) {
      this.state.num_japanese = num
    },
    changeNumJapanese (state, num) {
      state.num_japanese--
    },
    setNumFrench (state, num) {
      this.state.num_french = num
    },
    changeNumFrench (state, num) {
      state.num_french--
      console.log(state.num_french)
    },
  },
  getters: {},
  actions: {},
  modules: {}
})
