import Vue from "vue";
import Router from "vue-router";
import Login from "../views/Login";
import axios from "axios";
import VueAxios from "vue-axios";
import net from "../constants/constants";

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.prototype.NET = net;
Vue.use(VueAxios, axios);
Vue.use(Router);

export default new Router({
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "login",
      component: Login
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/questionnaireResulet",
      name: "questionnaireResulet",
      component: () =>
        import("../views/page/questionnaire/questionnaireResulet.vue"),
      meta: { title: "问卷管理" }
    },
    {
      path: "/home",
      component: () => import("../views/main/Home"),
      meta: { title: "自述文件" },
      children: [
        {
          path: "/main",
          component: () => import("../views/page/Main.vue"),
          meta: { title: "系统首页" }
        },
        {
          path: "/resources",
          name: "Resources",
          component: () => import("../views/page/Resources.vue"),
          meta: { title: "素材库" }
        },
       
        {
          path: "/course",
          component: () => import("../views/page/course.vue"),
          meta: { title: "课程列表" }
        },
        {
          path: "/userlist",
          component: () => import("../views/page/UserList.vue"),
          meta: { title: "用户列表" }
        },
        {
          path: "/oneToOne",
          component: () => import("../views/page/oneToOne.vue"),
          meta: { title: "一对一学员" }
        },
        {
          path: "/onetooneTeacher",
          name: "onetooneTeacher",
          component: () =>
            import("../views/page/onetooneTeacher.vue"),
          meta: { title: "一对一老师" }
        },
        {
          path: "/userIntention",
          name: "userIntention",
          component: () =>
            import("../views/page/userIntention.vue"),
          meta: { title: "意向用户" }
        },
        {
          path: "/goodsList",
          name: "goodsList",
          component: () => import("../views/page/goodsList.vue"),
          meta: { title: "商品管理" }
        },
        {
          path: "/marketingCourse",
          name: "marketingCourse",
          component: () => import("../views/page/marketingCourse.vue"),
          meta: { title: "营销课程" }
        },
        {
          path: "/freeLogin",
          name: "freeLogin",
          component: () => import("../views/page/freeLogin.vue"),
          meta: { title: "免密登录" }
        },
        {
          path: "/paymentList",
          component: () => import("../views/page/paymentList.vue"),
          meta: { title: "付款列表" }
        },
        {
          path: "/userLeave",
          component: () => import("../views/page/UserLeave.vue"),
          meta: { title: "用户留言" }
        },
        {
          path: "/orderList",
          name: "OrderList",
          component: () => import("../views/page/OrderList.vue"),
          meta: { title: "订单列表" }
        },
        {
          path: "/rolelist",
          name: "Rolelist",
          component: () => import("../views/page/Rolelist.vue"),
          meta: { title: "管理员" }
        },
        {
          path: "/smsLog",
          name: "SmsLog",
          component: () => import("../views/page/SmsLog.vue"),
          meta: { title: "短信日志" }
        },
        {
          path: "/sysLog",
          name: "SysLog",
          component: () => import("../views/page/SysLog.vue"),
          meta: { title: "系统日志" }
        },
        {
          path: "/personal",
          name: "personal",
          component: () => import("../views/page/Personal.vue"),
          meta: { title: "个人中心" }
        },
        {
          path: "/jurisdiction",
          name: "jurisdiction",
          component: () => import("../views/page/Jurisdiction.vue"),
          meta: { title: "权限管理" }
        },
        {
          path: "/agreements",
          name: "agreements",
          component: () => import("../views/page/agreements.vue"),
          meta: { title: "协议管理" }
        },
        {
          path: "/news",
          name: "news",
          component: () => import("../views/page/News.vue"),
          meta: { title: "新闻资讯" }
        },
        {
          path: "/service",
          name: "service",
          component: () => import("../views/page/Service.vue"),
          meta: { title: "客服管理" }
        },
        {
          path: "/fifty_notes",
          name: "fifty_notes",
          component: () => import("../views/page/Japanese/fifty_notes.vue"),
          meta: { title: "五十音列表" }
        },
        {
          path: "/grammar",
          name: "grammar",
          component: () => import("../views/page/Japanese/grammar.vue"),
          meta: { title: "语法列表" }
        },
        {
          path: "/realtest_paper",
          name: "realtest_paper",
          component: () => import("../views/page/Japanese/realtest_paper.vue"),
          meta: { title: "真题列表" }
        },
        {
          path: "/question_bank",
          name: "question_bank",
          component: () =>
            import("../views/page/Japanese/question_bank.vue"),
          meta: { title: "题库列表" }
        },

        {
          path: "/information",
          name: "information",
          component: () => import("../views/page/information.vue"),
          meta: { title: "信息收集" }
        },

        {
          path: "/collection_appList",
          name: "collection_appList",
          component: () => import("../views/page/collectionAppList.vue"),
          meta: { title: "App信息收集" }
        },
        {
          path: "/user_certificate",
          name: "user_certificate",
          component: () => import("../views/page/user_certificate.vue"),
          meta: { title: "用户证书" }
        },
        {
          path: "/points_record",
          name: "points_record",
          component: () => import("../views/page/points_record.vue"),
          meta: { title: "金币记录" }
        },
        {
          path: "/pointslist",
          name: "pointslist",
          component: () => import("../views/page/pointslist.vue"),
          meta: { title: "金币列表" }
        },
        {
          path: "/points_order",
          name: "points_order",
          component: () => import("../views/page/points_order.vue"),
          meta: { title: "金币订单" }
        },
        {
          path: "/points_mall",
          name: "points_mall",
          component: () => import("../views/page/points_mall.vue"),
          meta: { title: "金币商城" }
        },
        {
          path: "/taskList",
          name: "taskList",
          component: () => import("../views/page/taskList.vue"),
          meta: { title: "任务记录" }
        },

        {
          path: "/prizeList",
          name: "prizeList",
          component: () => import("../views/page/prizeList.vue"),
          meta: { title: "奖品列表" }
        },
        {
          path: "/winning_record",
          name: "winning_record",
          component: () => import("../views/page/winning_record.vue"),
          meta: { title: "中奖记录" }
        },

        {
          path: "/couponsList",
          name: "couponsList",
          component: () => import("../views/page/couponsList.vue"),
          meta: { title: "优惠券列表" }
        },
        {
          path: "/advertisingList",
          name: "advertisingList",
          component: () => import("../views/page/advertisingList.vue"),
          meta: { title: "广告列表" }
        },
        {
          path: "/land_page",
          name: "land_page",
          component: () => import("../views/page/land_page.vue"),
          meta: { title: "落地页" }
        },

        {
          path: "/Wechat_management",
          name: "Wechat_management",
          component: () => import("../views/page/wechat_management.vue"),
          meta: { title: "自动回复" }
        },
        {
          path: "/custom_menu",
          name: "custom_menu",
          component: () => import("../views/page/custom_menu.vue"),
          meta: { title: "自定义菜单" }
        },
        {
          path: "/japanese_radio_cateList",
          name: "japanese_radio_cateList",
          component: () =>
            import("../views/page/Japanese/japanese_radio_cateList.vue"),
          meta: { title: "电台列表" }
        },
        {
          path: "/daily_oneList",
          name: "daily_oneList",
          component: () =>
            import("../views/page/Japanese/daily_oneList.vue"),
          meta: { title: "每日一句" }
        },
        {
          path: "/japanese_curriculum",
          name: "japanese_curriculum",
          component: () =>
            import("../views/page/Japanese/japanese_curriculum.vue"),
          meta: { title: "课程列表" }
        },
        {
          path: "/auxiliary",
          name: "auxiliary",
          component: () =>
            import("../views/page/Japanese/auxiliary.vue"),
          meta: { title: "助词列表" }
        },
        {
          path: "/appointment",
          name: "appointment",
          component: () =>
            import("../views/page/Japanese/appointment.vue"),
          meta: { title: "约课列表" }
        },
        {
          path: "/sanctum_place",
          name: "sanctum_place",
          component: () =>
            import("../views/page/Japanese/sanctum_place.vue"),
          meta: { title: "圣地巡礼" }
        },
        {
          path: "/teacher_list",
          name: "teacher_list",
          component: () =>
            import("../views/page/teacher_list.vue"),
          meta: { title: "老师列表" }
        },
        {
          path: "/questionnaire",
          name: "questionnaire",
          component: () =>
            import("../views/page/questionnaire/index"),
          meta: { title: "问卷管理" }
        },
   
        {
          path: "/open_class",
          name: "open_class",
          component: () =>
            import("../views/page/Japanese/open_class.vue"),
          meta: { title: "精品公开课" }
        },
        {
          path: "/japanese_onetoone_appointment",
          name: "japanese_onetoone_appointment",
          component: () =>
            import("../views/page/Japanese/japanese_onetoone_appointment.vue"),
          meta: { title: "日语一对一" }
        },
        {
          path: "/japanese_resources",
          name: "japanese_resources",
          component: () =>
            import("../views/page/Japanese/japanese_resources.vue"),
          meta: { title: "日语素材库" }
        },

        // 韩语app
        {
          path: "/forty_notes",
          name: "forty_notes",
          component: () =>
            import("../views/page/Korean/forty_notes.vue"),
          meta: { title: "四十音列表" }
        },
        {
          path: "/korean_radio_cateList",
          name: "korean_radio_cateList",
          component: () =>
            import("../views/page/Korean/korean_radio_cateList.vue"),
          meta: { title: "电台列表" }
        },
        {
          path: "/korean_grammar",
          name: "korean_grammar",
          component: () => import("../views/page/Korean/grammar.vue"),
          meta: { title: "语法列表" }
        },
        {
          path: "/korean_question_bank",
          name: "korean_question_bank",
          component: () =>
            import("../views/page/Korean/question_bank.vue"),
          meta: { title: "题库列表" }
        },
        {
          path: "/korean_realtest_paper",
          name: "korean_realtest_paper",
          component: () => import("../views/page/Korean/realtest_paper.vue"),
          meta: { title: "真题列表" }
        },
        {
          path: "/korean_daily_oneList",
          name: "korean_daily_oneList",
          component: () =>
            import("../views/page/Korean/daily_oneList.vue"),
          meta: { title: "每日一句" }
        },
        {
          path: "/korean_curriculum",
          name: "korean_curriculum",
          component: () =>
            import("../views/page/Korean/korean_curriculum.vue"),
          meta: { title: "课程列表" }
        },
        {
          path: "/korean_auxiliary",
          name: "korean_auxiliary",
          component: () =>
            import("../views/page/Korean/auxiliary.vue"),
          meta: { title: "阶称大解析" }
        },
        {
          path: "/korean_appointment",
          name: "korean_appointment",
          component: () =>
            import("../views/page/Korean/appointment.vue"),
          meta: { title: "约课列表" }
        },
        {
          path: "/korean_sanctum_place",
          name: "korean_sanctum_place",
          component: () =>
            import("../views/page/Korean/sanctum_place.vue"),
          meta: { title: "玩转韩国" }
        },
        {
          path: "/sound_rule",
          name: "sound_rule",
          component: () =>
            import("../views/page/Korean/sound_rule.vue"),
          meta: { title: "音变规则" }
        },
        {
          path: "/idol",
          name: "idol",
          component: () =>
            import("../views/page/Korean/idol.vue"),
          meta: { title: "爱豆列表" }
        },
        {
          path: "/good_play",
          name: "good_play",
          component: () =>
            import("../views/page/Korean/good_play.vue"),
          meta: { title: "好剧列表" }
        },
        {
          path: "/korean_open_class",
          name: "korean_open_class",
          component: () =>
            import("../views/page/Korean/korean_open_class.vue"),
          meta: { title: "精品公开课" }
        },
        {
          path: "/korean_resources",
          name: "korean_resources",
          component: () =>
            import("../views/page/Korean/korean_resources.vue"),
          meta: { title: "韩语素材库" }
        },
        // 法语app
        {
          path: "/french_alphabet",
          name: "french_alphabet",
          component: () =>
            import("../views/page/French/french_alphabet.vue"),
          meta: { title: "法语字母" }
        },
        {
          path: "/questionbank_management",
          name: "questionbank_management",
          component: () =>
            import("../views/page/French/questionbank_management.vue"),
          meta: { title: "题库管理" }
        },
        {
          path: "/french_reading",
          name: "french_reading",
          component: () =>
            import("../views/page/French/french_reading.vue"),
          meta: { title: "法语阅读" }
        },
        {
          path: "/comment_management",
          name: "comment_management",
          component: () =>
            import("../views/page/French/comment_management.vue"),
          meta: { title: "评论管理" }
        },
        {
          path: "/post_management",
          name: "post_management",
          component: () =>
            import("../views/page/French/post_management.vue"),
          meta: { title: "发帖管理" }
        },
        {
          path: "/report_management",
          name: "report_management",
          component: () =>
            import("../views/page/French/report_management.vue"),
          meta: { title: "举报管理" }
        },
        {
          path: "/french_daily_oneList",
          name: "french_daily_oneList",
          component: () =>
            import("../views/page/French/french_daily_oneList.vue"),
          meta: { title: "每日一句" }
        },
        {
          path: "/common_word",
          name: "common_word",
          component: () =>
            import("../views/page/French/common_word.vue"),
          meta: { title: "常用词汇" }
        },
        {
          path: "/french_open_class",
          name: "french_open_class",
          component: () =>
            import("../views/page/French/french_open_class.vue"),
          meta: { title: "精品公开课" }
        },
        {
          path: "/french_resources",
          name: "french_resources",
          component: () =>
            import("../views/page/French/french_resources.vue"),
          meta: { title: "法语素材库" }
        },
        {
          path: "/french_onetoone_appointment",
          name: "french_onetoone_appointment",
          component: () =>
            import("../views/page/French/french_onetoone_appointment.vue"),
          meta: { title: "法语一对一" }
        },
        {
          path: "/french_wordList",
          name: "french_wordList",
          component: () =>
            import("../views/page/French/french_wordList.vue"),
          meta: { title: "法语词书" }
        },
        {
          path: "/french_wordResources",
          name: "french_wordResources",
          component: () =>
            import("../views/page/French/french_wordResources.vue"),
          meta: { title: "法语单词库" }
        },
        // 西班牙语app
        {
          path: "/spanish_resources",
          name: "spanish_resources",
          component: () => import("../views/page/Spanish/spanish_resources.vue"),
          meta: { title: "西语素材库" }
        },
        {
          path: "/spanish_alphabet",
          name: "spanish_alphabet",
          component: () => import("../views/page/Spanish/spanish_alphabet.vue"),
          meta: { title: "西语元辅音表" }
        },
        {
          path: "/spanish_alphabet_video",
          name: "spanish_alphabet_video",
          component: () => import("../views/page/Spanish/spanish_alphabet_video.vue"),
          meta: { title: "西语元辅音视频" }
        },
        {
          path: "/spanish_screeningroom",
          name: "spanish_screeningroom",
          component: () => import("../views/page/Spanish/spanish_screeningroom.vue"),
          meta: { title: "西语放映厅" }
        },
        // 德语app
        {
          path: "/german_resources",
          name: "german_resources",
          component: () => import("../views/page/German/german_resources.vue"),
          meta: { title: "德语素材库" }
        },
        {
          path: "/material_resources",
          props: true,
          component: () => import("../views/page/material/material_resources.vue"),
          meta: { title: "素材库" }
        },
        {
          path: "/",
        }
      ]
    }
  ]
});
