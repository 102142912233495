import ElementUI from "element-ui";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import qs from "qs";
import md5 from 'js-md5';


import "./assets/icon/iconfont.css";
import "../src/assets/theme/theme/index.css";

// 导入时间过滤第三方包
import moment from "moment";

// 引用创建好的指令
import directives from './directives'

Vue.prototype.$axios = axios; //全局注册，使用方法为:this.$axios
Vue.prototype.qs = qs; //全局注册，使用方法为:this.qs
Vue.prototype.$md5 = md5;
Vue.config.productionTip = false;

Vue.prototype.$moment = moment;
moment.locale("zh-cn");
var that = this;

import { loadMore } from "./utils/loadmore";
import {filterPrice} from "./filter/filterPrice"
let obj = {
  filterPrice
}
for(let i in obj){
  Vue.filter(i,obj[i])
}

Vue.directive("loadmore", { bind: loadMore });

Vue.use(VueAxios, axios);
Vue.use(ElementUI);
// 全局注册指令
Vue.use(directives)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
