<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
const script = document.createElement("script");
script.src = "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js";
document.body.appendChild(script);

export default {
  name: "app",
  created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  }
};
</script>
<style lang="scss">
@import url("./assets/css/main.css");
@import url("./assets/css/color-dark.css");
@import url("//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.css");
body {
  margin: 0;
  padding: 0;
}
* {
  font-size: 12px;
}
.pagination {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
</style>
