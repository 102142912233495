<template>
  <div id="bg">
    <div style="margin-top: 100px">
      <h2 class="logoText">
        <img src="../assets/logo.png" alt="logo" />
        <span>湖南恰学教育管理后台</span>
      </h2>
    </div>
    <el-form
      ref="loginForm"
      :model="form"
      :rules="rules"
      label-width="80px"
      class="login-box"
    >
      <el-form-item>
        <h3 class="login-title">欢迎登录</h3>
      </el-form-item>
      <el-form-item label="账号：" prop="username">
        <el-input
          type="text"
          placeholder="请输入账号"
          v-model="form.username"
          oninput="this.value=this.value.replace(/\D/g, '')"
        />
      </el-form-item>
      <el-form-item label="密码：" prop="password">
        <el-input
          type="password"
          placeholder="请输入密码"
          v-model="form.password"
        />
      </el-form-item>
      <el-form-item>
        <el-button class="register-btn" type="primary" v-on:click="handleLogin"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import store from "@/store";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { login } from "../api/api";
export default {
  name: "Login",
  data() {
    var validatorPhone = function(rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      form: {
        username: "",
        password: "",
        againPassword: "",
        url: ""
        // code: "",
        // key: ""
      },
      // 表单验证，需要在 el-form-item 元素中增加 prop 属性
      rules: {
        username: [
          { required: true, message: "账号不可为空", trigger: "change" },
          { validator: validatorPhone, trigger: "change" }
        ],
        password: [
          { required: true, message: "密码不可为空", trigger: "change" },
          {
            min: 5,
            max: 30,
            message: "密码长度在 5 到 30 个字符",
            trigger: "change"
          }
        ]
      }
    };
  },
  created() {
    if (getToken()) {
      this.$router.push("/Main");
    }
  },
  methods: {
    handleLogin() {
      var data = {
        phone: this.form.username,
        password: this.form.password
      };
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          login(data).then(res => {
            // this.$message.success("登录成功");
            if (res.code !== 1) {
              this.$message.error(res.code);
              return;
            }
            setToken(res.access_token);
            store.commit("setToken", res.access_token);
            this.$router.push("Main");
          });
        } else {
          this.$message.error("请正确填写用户名及密码!");
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
.login-box {
  width: 25%;
  margin: 120px auto;
  padding: 35px 55px 35px 35px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  text-align: center;
  background: #1aa779;
}
::v-deep .el-form-item__label {
  color: white;
}
.login-title {
  text-align: center;
  margin: 0 auto 30px auto;
  color: #fff;
  font-size: 25px;
}
.register-btn {
  width: 100%;
  margin: 0 auto;
  background-color: #36ca99;
}
.logoText {
  color: #006934;
  font-size: 36px;
  line-height: 60px;
  display: flex;
  justify-content: center;
}
.logoText span {
  margin-left: 30px;
  font-size: 36px;
}
#bg {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background-image: url(../assets/img/bg_main.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
}
</style>
