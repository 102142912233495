import axios from "axios";
import store from "@/store";
import { Message, MessageBox } from "element-ui";
import { getToken, removeToken } from "@/utils/auth";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 120000 // request timeout
});

//http request 拦截器
service.interceptors.request.use(
  config => {
    if (config.method === "get") {
      config.headers = {
        "Content-Type": "application/json",
        Authorization: getToken()
        // usertoken: getToken()
      };
    } else if (config.method === "post") {
      config.headers = {
        "Content-Type": "application/json",
        Authorization: getToken()
      };
    } else if (config.method === "delete") {
      config.headers = {
        "Content-Type": "application/json",
        Authorization: getToken()
      };
    } else if (config.method === "put") {
      config.headers = {
        "Content-Type": "application/json",
        Authorization: getToken()
      };
    }
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

//http response 拦截器
service.interceptors.response.use(
  res => {
    if (res.config.url == '/admin/base/exportOrder' || res.config.url == '/admin/base/exportCollection') {
      return res;
    }
    if (res.data.code == 1) {
      return res.data;
    } else if (res.data.code == -1) {
      Message({
        message: res.data.msg || "Error",
        type: "error",
        duration: 2 * 1000
      });
      MessageBox.alert('登录已经信息已经过期，请重新登陆！', '登陆超时', {
        confirmButtonText: '跳转登录页面',
        callback: action => {
          removeToken()
          window.location = '/'
        }
      });
    }
    else {
      Message({
        message: res.data.msg || "Error",
        type: "error",
        duration: 2 * 1000
      });
    }
  },
  error => {
    console.log("err" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 2 * 1000
    });
    // return Promise.reject(error);
  }
);

export default service;
