import request from "@/utils/request";
import md5 from 'js-md5'
export function login(data) {
    let loginForm = {
        password:md5(data.password),
        phone:data.phone
    }
    return request({
        url:'/admin/base/rUserLogin',
        method:'post',
        data:loginForm
        }
    )
}

export function getCaptcha(query){
    return request({
        url:'/admin/base/captcha',
        method:'post'
        }
    )
}
// 课程选择器
export function getCourseSelection(query){
    return request({
        url:'/admin/base/selectGoods',
        method:'get'
        }
    )
}

